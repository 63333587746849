import "lazysizes";
import {FloatLabels} from "@keodesign/float-labels";

var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
window.onload = function() {    
	setupSlideMenu();
	setupFAQ();
	new FloatLabels(".js-float-wrap");
};

export function setupSlideMenu(){
	const menu_buttons = [...document.querySelectorAll("#mobile-menu, .slide-menu h3")];
	menu_buttons.forEach(el => {
		el.addEventListener("click", ()=>{
			const body = document.querySelector("body");
			body.classList.toggle("slide-menu-active");
		});
	});
	const menu_items = [...document.querySelectorAll(".slide-menu li")];
	menu_items.forEach((element) => {		
		if ([...element.querySelectorAll("ul")].length > 0) {
			element.insertAdjacentHTML("beforeend","<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
		}
	});
	const menu_expanders = [...document.querySelectorAll(".slide-menu .expand")];
	menu_expanders.forEach(el => {
		el.addEventListener("click", (e)=> {
			e.stopPropagation();
			el.closest("li").classList.toggle("expand-active");
		});
	});

}

export function setupFAQ(){
	const faq_cards = document.querySelectorAll(".faq-item");
	let first_flag = true;
	if(faq_cards){
		faq_cards.forEach(el => {
			let title = el.querySelector(":scope > .faq-title");
			if (title){
				title.addEventListener("click", () => {
					el.classList.toggle("fold");
				});
			}
			let content = el.querySelector(":scope > .faq-content");
			content.style.maxHeight = content.clientHeight + "px";
			if(first_flag){
				first_flag = false;
			}
			else{
				el.classList.add("fold");
			}
		
		});
	}	
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});